<template>
  <div class="mb-8">
    <section class="text-gray-700">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              text-center
              title-font
              text-gray-900
              mb-4
            "
          >
            Refund Policy
          </h1>
        </div>
      </div>
    </section>

    <div class="container leading-relaxed mx-auto">
      <p>
        Thank you for your purchase. We hope you are happy with your purchase.
        However, if you are not completely satisfied with your purchase for any
        reason, you may return it to us for an exchange only. Please see below
        for more information on our return policy.
      </p>

      <br />
      <h3 class="title-font text-bold-600 mb-4 text-2xl">Returns</h3>
      <p class="text-base">
        All returns must be postmarked within seven (7) days of the purchase
        date. All returned items must be in new and unused condition, with all
        original tags and labels attached.
      </p>
      <h3 class="title-font text-bold-600 mt-2 mb-4 text-2xl">Return Process</h3>
      <p class="text-base">
        To return an item, please email customer service at
        <a class="text-indigo-600 no-underline hover:underline" href="mailto:hello@alphasofthub.com   ">hello@alphasofthub.com</a> to
        obtain a Return Merchandise Authorization (RMA) number. After receiving
        a RMA number, place the item securely in its original packaging and
        include your proof of purchase, and mail your return to the following
        address: hello@alphasofthub.com
      </p>
      <p class="tect-base">Sale items are FINAL SALE and cannot be returned</p>
      <p class="text-base">
        Please note that if you have any questions about your return, please
        contact us via email at
        <a
          href="mailto:hello@alphasofthub.com"
          target="_blank"
          class="text-base text-indigo-600 no-underline hover:underline"
          >hello@alphasofthub.com</a
        >.
      </p>
    </div>
  </div>
</template>